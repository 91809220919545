import { Box, Typography } from "@mui/material";
import * as React from "react";

const NotFoundPage = () => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography
      variant="h4"
      component="div"
      sx={{
        marginTop: "33vh",
      }}
    >
      La page que vous cherchez n'existe pas.
    </Typography>
  </Box>
);
export default NotFoundPage;
