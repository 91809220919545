import {
  Box,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Download } from "@mui/icons-material";
import { ReactNode } from "react";
import { downloadBlob, statusLabel } from "../../utils";
import dayjs from "dayjs";

export const TrainReadyProcessDetail = ({
  process,
}: {
  process: TrainReadyProcessView;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(2),
      }}
    >
      <Typography variant="h6">Train prêt au départ</Typography>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: (theme) => theme.spacing(1),
        }}
      >
        {process.messages.map((msg) => (
          <TrainReadyMessagePanel message={msg} key={msg.id} />
        ))}
      </Box>
    </Box>
  );
};

const TrainReadyMessagePanel = ({
  message,
}: {
  message: TrainReadyMessageView;
}) => {
  const downloadRaw = () => {
    const filename = `trm_${
      message.raw.id
    }_${message.status.toLowerCase()}.json`;
    const blob = new Blob([JSON.stringify(message.raw, null, 2)], {
      type: "text/plain;charset=utf-8",
    });

    downloadBlob(blob, filename);
  };

  const raw = message.raw;

  return (
    <Paper
      variant="outlined"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        padding: (theme) => theme.spacing(1),
        backgroundColor: (theme) => theme.palette.grey.A200,
        gap: (theme) => theme.spacing(0.25),
      }}
    >
      <DataField label="Statut" value={message.status} format={statusLabel} />
      <DataField
        label="Date d'envoi"
        value={dayjs(message.timestamp).format("[Le] DD/MM/YYYY à HH[h]mm")}
      />
      {raw.status === "REQUESTED" && raw.data && (
        <>
          <DataField
            label="Train prêt"
            value={raw.data.ready}
            format={(val) => (val ? "Oui" : "Non")}
          />
          <DataField
            label="Cause du retard"
            value={raw.data.delayText}
            format={(val) => (val === null ? "-" : val)}
          />
          <DataField
            label="Retard estimé"
            value={raw.data.delayPeriod}
            format={(val) =>
              typeof val === "string"
                ? Math.round(dayjs.duration(val).asMinutes()) + " minutes"
                : "-"
            }
          />
        </>
      )}
      {raw.status === "ERROR" && raw.data && (
        <>
          <DataField label="Code" value={raw.data.code} />
          <DataField label="Message" value={raw.data.message} />
        </>
      )}
      <Tooltip title="Télécharger le message brut">
        <IconButton
          onClick={downloadRaw}
          sx={{
            alignSelf: "end",
            backgroundColor: "white",
            borderRadius: 2,
            borderColor: "#4a4a4a",
            margin: (theme) => theme.spacing(0.5),
          }}
        >
          <Download />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};

type DataFieldProps = {
  label: string;
  value: any;
  format?: (value: any) => ReactNode;
};

const DataField = ({ label, value, format }: DataFieldProps) => {
  if (value === undefined) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: (theme) => theme.spacing(0, 2),
      }}
    >
      <strong>{label} : </strong>
      {format ? format(value) : value}
    </Box>
  );
};
