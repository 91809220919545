export const statusLabel = (status: TrainReadyStatus) => {
  switch (status) {
    case "REQUESTED":
      return "Demande";
    case "SENT":
      return "Envoyé à l'IC";
    case "ERROR":
      return "Erreur";
  }
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};
