import * as React from "react";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Search } from "@mui/icons-material";

type FilterPanelProps = {
  initialFilters: TrainStepSearchRequest;
  onChange: (filters: TrainStepSearchRequest) => void;
};

export const FilterPanel = ({ initialFilters, onChange }: FilterPanelProps) => {
  const [query, setQuery] = useState("");
  const [from, setFrom] = React.useState<Dayjs | null>(null);
  const [to, setTo] = React.useState<Dayjs | null>(null);

  useEffect(() => {
    setQuery(initialFilters.query);
    setFrom(initialFilters.from ?? null);
    setTo(initialFilters.to ?? null);
  }, [initialFilters]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onChange({
          query,
          from: from?.isValid() ? from : null,
          to: to?.isValid() ? to : null,
        });
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: (theme) => theme.spacing(3),
          gap: (theme) => theme.spacing(3),
        }}
      >
        <TextField
          variant={"standard"}
          name="query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          label="Nom de l'étape ou du sillon"
        />
        <DatePicker
          label="Depuis le"
          value={from}
          onChange={(val) => setFrom(val)}
          renderInput={(params) => (
            <TextField {...params} variant="standard" name="from" />
          )}
        />
        <DatePicker
          label="Jusqu'au"
          value={to}
          onChange={(val) => setTo(val)}
          renderInput={(params) => (
            <TextField {...params} variant="standard" name="to" />
          )}
        />
        <Button
          variant="outlined"
          type="submit"
          sx={{ gap: (theme) => theme.spacing(1) }}
        >
          Rechercher
          <Search />
        </Button>
      </Box>
    </form>
  );
};
