import { useParams } from "react-router-dom";
import { useLastSearch, usePageFetch } from "../../hooks";
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { ArrowBack, Cancel } from "@mui/icons-material";
import dayjs from "dayjs";
import MessageTypeIndicator from "../../components/MessageTypeIndicator";
import { TrainReadyProcessPanel } from "./TrainReadyProcessPanel";
import { TrainStepIdentifier } from "../../components/TrainStepIdentifier";
import { TrainReadyProcessDetail } from "./TrainReadyProcessDetail";

const TrainStepPage = () => {
  const { lastSearch } = useLastSearch();
  const { id } = useParams();
  const { loading, error, data } = usePageFetch<TrainStepResponse>(
    `/api/step/${id}`
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(2),
      }}
    >
      <Link
        underline="hover"
        variant="h5"
        href={lastSearch ? `/?${lastSearch}` : "/"}
        color="h5.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <ArrowBack />
        <Typography variant="h5">Revenir à la recherche</Typography>
      </Link>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="div">
            Erreur lors de la récupération de l'étape.
          </Typography>
        </Box>
      )}

      {data && <TrainStepDescription step={data} />}
    </Box>
  );
};

const TrainStepDescription = ({ step }: { step: TrainStepView }) => {
  const summary = step.summary;
  const [selectedProcess, setSelectedProcess] =
    useState<TrainReadyProcessView>();
  const [processDetailOpen, setProcessDetailOpen] = useState<boolean>(false);

  const onProcessSelected = (process: TrainReadyProcessView) => {
    setSelectedProcess(process);
    setProcessDetailOpen(true);
  };

  const closeProcessDetail = () => setProcessDetailOpen(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(1),
        width: "100%",
      }}
    >
      <Drawer
        anchor="right"
        open={processDetailOpen}
        onClose={closeProcessDetail}
      >
        <Box
          sx={(theme) => ({
            position: "relative",
            backgroundColor: "white",
            padding: (theme) => theme.spacing(2),
            width: theme.breakpoints.values.sm,
            [theme.breakpoints.down("sm")]: {
              width: "100vw",
            },
          })}
        >
          <IconButton
            onClick={closeProcessDetail}
            sx={{
              position: "absolute",
              right: (theme) => theme.spacing(1),
              top: (theme) => theme.spacing(1),
            }}
          >
            <Cancel />
          </IconButton>
          {selectedProcess && (
            <TrainReadyProcessDetail process={selectedProcess} />
          )}
        </Box>
      </Drawer>
      <TrainStepIdentifier step={summary} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(1),
        }}
      >
        <MessageTypeIndicator
          type="TPD"
          count={summary.trm.count}
          date={dayjs(summary.trm.date)}
          status={summary.trm.status}
        />
        <MessageTypeIndicator type="TCO" count={0} />
        <MessageTypeIndicator type="TRI" count={0} />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: (theme) => theme.spacing(1),
        }}
      >
        {step.trms.map((trm) => (
          <TrainReadyProcessPanel
            process={trm}
            onClick={onProcessSelected}
            key={trm.id}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TrainStepPage;
