import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import msalPromise, { getAuthenticatedUser } from "./config/auth";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

/*
 * Checking that the user is logged in before initiating the React
 * application. If the user is not logged in, a redirection to the
 * Azure AD is triggered.
 */
msalPromise.then(async () => {
  const user = await getAuthenticatedUser();
  if (user) {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
