import { Box, Paper, Typography } from "@mui/material";
import * as React from "react";
import dayjs from "dayjs";
import { statusLabel } from "../../utils";

type TrainReadyProcessPanelProps = {
  process: TrainReadyProcessView;
  onClick: (process: TrainReadyProcessView) => void;
};

export const TrainReadyProcessPanel = ({
  process,
  onClick,
}: TrainReadyProcessPanelProps) => {
  return (
    <Paper
      key={process.id}
      onClick={() => onClick(process)}
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        padding: (theme) => theme.spacing(1),
        gap: (theme) => theme.spacing(1),
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2">#{process.id.substring(0, 8)}</Typography>
        <Typography variant="body2">
          Envoi le {dayjs(process.date).format("DD/MM/YYYY à HH[h]mm")}
        </Typography>
      </Box>
      <Typography variant="h6">Train prêt au départ</Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">
          <strong>Statut : </strong>
          {statusLabel(process.status)}
        </Typography>
        <Typography variant="body2">{process.count ?? 0} événements</Typography>
      </Box>
    </Paper>
  );
};
