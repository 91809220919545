import { RouteObject } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import TrainStepPage from "./pages/train-step/TrainStepPage";
import NotFoundPage from "./pages/not-found/NotFoundPage";

const routes: RouteObject[] = [
  { path: "/", element: <HomePage /> },
  { path: "/etape/:id", element: <TrainStepPage /> },
  { path: "*", element: <NotFoundPage /> },
];

export default routes;
