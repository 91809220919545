import { createTheme, ThemeOptions } from "@mui/material";

const defaultTheme = createTheme({} as ThemeOptions);

const theme = createTheme({
  palette: {
    background: {
      default: "#f4f4f4",
    },
    primary: {
      contrastText: "#fff",
      dark: "#388e3c",
      main: "#639f65",
      light: "#b9f6ca",
    },
    secondary: {
      contrastText: "#fff",
      dark: "#388e3c",
      main: "#639f65",
      light: "#b9f6ca",
    },
    error: {
      contrastText: "#fff",
      dark: "#c62828",
      main: "#d32f2f",
      light: "#ef5350",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(189, 204, 41)",
          color: "white",
        },
      },
    },
  },

  typography: {
    fontWeightBold: 700,
    h1: {
      fontSize: defaultTheme.typography.pxToRem(36),
      fontWeight: 500,
      color: "#4a4a4a",
    },
    h4: {
      color: "#4a4a4a",
    },
    h5: {
      color: "#4a4a4a",
    },
    h6: {
      fontSize: defaultTheme.typography.pxToRem(24),
      color: "#4a4a4a",
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: defaultTheme.typography.pxToRem(16),
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: defaultTheme.typography.pxToRem(20),
    },
    body1: {
      color: "#4a4a4a",
    },
    body2: {
      color: "#9b9b9b",
      lineHeight: 1.5,
    },
    button: {
      textTransform: "none",
    },
  },
});

export default theme;
