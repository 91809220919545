import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useLastSearch, usePageFetch } from "../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Cancel,
  FilterAlt,
  FilterAltOffOutlined,
  FilterAltOutlined,
} from "@mui/icons-material";
import { FilterPanel } from "./FilterPanel";
import qs from "qs";
import { SearchResultsPanel } from "./SearchResultsPanel";

const emptyFilters: TrainStepSearchRequest = {
  query: "",
  from: null,
  to: null,
};

const HomePage = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { setLastSearch } = useLastSearch();

  const filters: TrainStepSearchRequest = useMemo(
    () => fromParams(params),
    [params]
  );

  useEffect(() => {
    setLastSearch(toParams(filters));
  }, [setLastSearch, filters]);

  const applyFilters = (filters: TrainStepSearchRequest) => {
    navigate(`/?${toParams(filters)}`);
  };
  const resetFilters = () => {
    applyFilters(emptyFilters);
  };

  const hasFilters =
    filters.query !== emptyFilters.query ||
    filters.from !== emptyFilters.from ||
    filters.to !== emptyFilters.to;

  const pageQuery = useMemo(
    () => `/api/home/search?${toParams(filters)}`,
    [filters]
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const { loading, error, data } =
    usePageFetch<TrainStepSearchResponse>(pageQuery);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        gap: (theme) => theme.spacing(1),
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: (theme) => theme.spacing(1),
        }}
      >
        <Typography variant="h5">Recherche par étape</Typography>
        <Box
          sx={{
            flex: "1 0 auto",
          }}
        />
        {hasFilters ? (
          <>
            <IconButton
              onClick={() => setDrawerOpen(true)}
              sx={{
                backgroundColor: "primary.light",
              }}
            >
              <FilterAlt />
            </IconButton>
            <IconButton onClick={() => resetFilters()}>
              <FilterAltOffOutlined />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={() => setDrawerOpen(true)}>
            <FilterAltOutlined />
          </IconButton>
        )}
      </Box>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{
              marginTop: "33vh",
            }}
          >
            Erreur lors de la récupération des étapes.
          </Typography>
        </Box>
      )}
      {data && <SearchResultsPanel results={data.steps} />}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={(theme) => ({
            position: "relative",
            backgroundColor: "white",
            width: 400,
            [theme.breakpoints.down("sm")]: {
              width: "400px",
            },
            padding: (theme) => theme.spacing(1),
          })}
        >
          <IconButton
            onClick={() => setDrawerOpen(false)}
            sx={(theme) => ({
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
            })}
          >
            <Cancel />
          </IconButton>
          <FilterPanel initialFilters={filters} onChange={applyFilters} />
        </Box>
      </Drawer>
    </Box>
  );
};

const toParams = ({ query, from, to }: TrainStepSearchRequest) => {
  return qs.stringify({
    query: query !== "" ? query : undefined,
    from: from ? from.format("YYYY-MM-DD") : undefined,
    to: to ? to.format("YYYY-MM-DD") : undefined,
  });
};

const fromParams = (params: URLSearchParams): TrainStepSearchRequest => {
  return {
    query: params.get("query") ?? "",
    from: params.get("from") ? dayjs(params.get("from")) : null,
    to: params.get("to") ? dayjs(params.get("to")) : null,
  };
};

export default HomePage;
