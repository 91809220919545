import * as Sentry from "@sentry/react";

function shouldInitSentry() {
  const env = process.env.NODE_ENV;
  return env !== "development";
}

function getSentryEnvironment(): "int" | "re7" | "prod" | "unknown" {
  switch (window.location.hostname) {
    case "clicint.captrain.fr":
      return "int";
    case "clicre7.captrain.fr":
      return "re7";
    case "clic.captrain.fr":
      return "prod";
    default:
      return "unknown";
  }
}

export const init = () => {
  if (shouldInitSentry()) {
    Sentry.init({
      dsn: "",
      environment: getSentryEnvironment(),
    });
  }
};
