import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ArrowRight } from "@mui/icons-material";
import * as React from "react";

export const TrainStepIdentifier = ({ step }: { step: TrainStepSummary }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            padding: (theme) => theme.spacing(0, 3),
            borderRadius: 1,
            fontWeight: "bold",
            backgroundColor: "rgba(31,37,55,.35)",
            color: "white",
          }}
        >
          {step.trainId}
        </Box>
        <Typography variant="body2">
          Départ le {dayjs(step.departureDate).format("DD/MM/YYYY à HH[h]mm")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: (theme) => theme.spacing(1),
        }}
      >
        <Typography variant="body1" align="left">
          {step.departureStation.label}
        </Typography>
        <ArrowRight />
        <Typography variant="body1" align="left">
          {step.arrivalStation.label}
        </Typography>
      </Box>
    </Box>
  );
};
