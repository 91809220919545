import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useUser } from "./providers/UserProvider";

export const usePageFetch = <T>(query: string) => {
  const { fetch } = useUser();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setData(undefined);
        setError(undefined);
        const response = await fetch(query);
        if (response.ok) {
          const body: T = await response.json();
          setData(body);
        } else if (response.status === 404) {
          setError("La page à laquelle vous essayez d'accéder n'existe pas.");
        } else if (response.status === 403) {
          setError(
            "Vous n'avez pas les droits nécessaires pour accéder à cette page."
          );
        } else {
          setError(
            "Erreur lors du chargement de la page. Veuillez réessayer ultérieurement."
          );
          Sentry.captureMessage("Erreur lors du chargement de la page");
        }
      } catch (e) {
        setError(
          "Erreur lors du chargement de la page. Veuillez réessayer ultérieurement."
        );
        Sentry.captureException(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData().then();
  }, [query, fetch]);

  return {
    data,
    error,
    loading,
  };
};

/** Hook to handle the last search state from other pages. */
export const useLastSearch = () => {
  const storageKey = "lastSearch";
  const [lastSearch, setLastSearch] = useState<string>();

  useEffect(() => {
    setLastSearch(sessionStorage.getItem(storageKey) ?? undefined);
  }, []);

  return {
    lastSearch: lastSearch,
    setLastSearch: (filterParams: string) => {
      setLastSearch(lastSearch);
      sessionStorage.setItem(storageKey, filterParams);
    },
  };
};
