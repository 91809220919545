import { Box, Button, Chip, Typography } from "@mui/material";
import * as React from "react";
import { Dayjs } from "dayjs";
import { statusLabel } from "../utils";

type MessageTypeIndicatorProps = {
  type: string;
  count: number;
  status?: TrainReadyStatus;
  date?: Dayjs;
  onClick?: () => void;
};

const MessageTypeIndicator = ({
  type,
  count,
  status,
  date,
  onClick,
}: MessageTypeIndicatorProps) => {
  return (
    <Button
      variant="outlined"
      disabled={count === 0}
      sx={{
        display: "flex",
        flex: "1 1 0px",
        flexDirection: "column",
        alignItems: "start",
        padding: (theme) => theme.spacing(0.5, 1),
      }}
      onClick={() => onClick && onClick()}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="button" align="left">
          {type}
        </Typography>
        {count > 0 && <Chip size="small" label={count} />}
      </Box>
      <Typography variant="body2" align="left" color="#4a4a4a">
        {status ? statusLabel(status) : "-"}
      </Typography>
      <Typography variant="body2" align="left" color="#4a4a4a">
        {date ? <>Le {date.format("DD/MM/YYYY à HH[h]mm")}</> : "-"}
      </Typography>
    </Button>
  );
};

export default MessageTypeIndicator;
