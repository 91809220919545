import React from "react";
import "./App.css";
import { BrowserRouter, useRoutes } from "react-router-dom";
import routes from "./routes";
import Layout from "./layout/Layout";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as sentry from "./sentry";
import { UserProvider } from "./providers/UserProvider";
import dayjs from "dayjs";

import durationPlugin from "dayjs/plugin/duration";
dayjs.extend(durationPlugin);

sentry.init();

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <Layout>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </Layout>
          </UserProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

const AppRoutes = () => {
  return useRoutes(routes);
};

export default App;
