import { Box, Paper, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { TrainStepIdentifier } from "../../components/TrainStepIdentifier";
import MessageTypeIndicator from "../../components/MessageTypeIndicator";
import dayjs from "dayjs";

export type SearchResultsPanelProps = {
  results: TrainStepSummary[];
};

export const SearchResultsPanel = ({ results }: SearchResultsPanelProps) => {
  if (results.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            marginTop: "33vh",
          }}
        >
          Aucun résultat trouvé.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        gap: (theme) => theme.spacing(2),
        flex: "0 1 auto",
        width: "100%",
      }}
    >
      {results.map((r) => (
        <SearchResultItem result={r} key={r.id} />
      ))}
    </Box>
  );
};

const SearchResultItem = ({ result }: { result: TrainStepSummary }) => {
  const navigate = useNavigate();
  const { trm } = result;
  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        gap: theme.spacing(1),
        cursor: "pointer",
      })}
      onClick={() => navigate(`/etape/${result.id}`)}
    >
      <TrainStepIdentifier step={result} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(1),
        }}
      >
        <MessageTypeIndicator
          type="TPD"
          count={trm.count}
          date={dayjs(trm.date)}
          status={trm.status}
          onClick={() => navigate(`/etape/${result.id}`)}
        />
        <MessageTypeIndicator type="TCO" count={0} />
        <MessageTypeIndicator type="TRI" count={0} />
      </Box>
    </Paper>
  );
};
