import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getAuthenticatedUser } from "../config/auth";

type UserContextType = {
  user?: User;
  fetch: typeof fetch;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<User>();

  useEffect(() => {
    getAuthenticatedUser().then(setAuthenticatedUser);
  }, []);
  // We don't want to listen on account changes as it is only there to check if we can keep its info.

  const authenticatedFetch: typeof fetch = useCallback(
    async (input, init) => {
      if (!authenticatedUser) {
        throw new Error("échec de connexion");
      }
      const fetchInit: RequestInit = {
        ...init,
      };
      const headers = new Headers(fetchInit.headers);
      headers.append("Authorization", `Bearer ${authenticatedUser.jwt}`);
      fetchInit.headers = headers;

      return await fetch(input, fetchInit);
    },
    [authenticatedUser]
  );

  return (
    <UserContext.Provider
      value={{
        user: authenticatedUser,
        fetch: authenticatedFetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUser = () => {
  const userInfo = useContext(UserContext);
  if (userInfo === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return userInfo;
};
