import { ReactNode, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useUser } from "../providers/UserProvider";
import { AccountCircle, Logout } from "@mui/icons-material";
import { interactiveLogout } from "../config/auth";

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <Box>
      <CssBaseline />
      <HeaderBar />
      <Container
        sx={{
          paddingTop: (theme) => theme.spacing(2),
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

const HeaderBar = () => {
  return (
    <AppBar position="sticky" variant="elevation">
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "64px",
            padding: (theme) => theme.spacing(0, 1),
            backgroundColor: "white",
          }}
        >
          <img src="/logo128.png" alt="logo" height="48px" />
        </Box>
        <Typography
          variant="h1"
          color="white"
          sx={{
            marginLeft: (theme) => theme.spacing(3),
          }}
        >
          Client IC
        </Typography>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

const UserMenu = () => {
  const { user } = useUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  return (
    <>
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setMenuOpen(true);
        }}
        sx={{
          color: "white",
          gap: (theme) => theme.spacing(2),
          justifySelf: "right",
          ml: "auto",
        }}
      >
        <Typography
          sx={(theme) => ({
            color: "white",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          {user?.name}
        </Typography>
        <AccountCircle fontSize="large" />
      </Button>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={() => setMenuOpen(false)}
        elevation={1}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box
          sx={{
            color: "rgb(189, 204, 41)",
            display: "flex",
            gap: (theme) => theme.spacing(2),
            alignItems: "center",
            justifyContent: "end",
            padding: (theme) => theme.spacing(0, 2, 1, 2),
          }}
        >
          <Typography>
            <strong>{user?.name}</strong>
          </Typography>
          <AccountCircle fontSize="large" />
        </Box>
        <Divider />
        <MenuItem
          onClick={() => interactiveLogout()}
          sx={{
            color: "rgb(189, 204, 41)",
            display: "flex",
            gap: (theme) => theme.spacing(2),
            alignItems: "center",
          }}
        >
          Se Déconnecter
          <Logout />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Layout;
